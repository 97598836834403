@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap");


html {
    scroll-behavior: smooth;
}

* {
    scrollbar-width: none;
    -ms-overflow-style: none;
  
}

*::-webkit-scrollbar {
    display: none;
}
body{
    background-color: black;
    max-width: 100%;
}


