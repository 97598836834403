@keyframes duckWalk {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

.duck-walk-animation {
    animation: duckWalk 1s ease-in-out infinite;
}
