.navbar{
    max-width: 100%;
    border: 1px soli white;
}
@media (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
    nav {
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .hamburger-menu-container {
        position: absolute;
        top: 2vh;
        right: 2vh;
        padding: 10px;
    }
}

.code-icon {
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 5vh;
    font-weight: bold;
    background: white;
    color: black;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3)
}
